import React from 'react';
import styled, { css } from 'styled-components';

export const Cont = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	background-color: #FFF;
	padding: 3rem;
`
export const numCss = css`
	height: 1.4rem;
	width: auto;
`

export const Title = styled.h3`
	font-size: 1.4rem;
	font-weight: bold;
	margin-top: 2rem;
`

export const En = styled.p`
	margin-top: 0.7rem;
	& > svg{
		height: 0.8rem;
		width: auto;
	}
`
