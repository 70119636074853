import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import Img from 'gatsby-image/withIEPolyfill'
import Triangle from '@components/common/triangle';

import { color, breakpoint } from '@src/settings';

const Hero = (props) => (
	<Cont>
		<Img className="img" fluid={ props.img.childImageSharp.fluid } fade={ true } />
		<Triangle
			className="triangle"
		/>
		<div className="txt">
			<h2>{ props.title }</h2>
			<p>{ props.subTitle }</p>
		</div>
	</Cont>
);

const Cont = styled.div`
	position: relative;
	overflow: hidden;
	& > .img{
		position: relative;
		z-index: 0;
	}
	& > .txt{
		position: absolute;
		left: 6rem;
		top: 50%;
		transform: translateY( -40% );
		z-index: 2;
		& > h2{
			& > svg{
				height: 2.8rem;
				width: initial;
			}
		}
		& > p{
			margin-top: 1.6rem;
			font-size: 1.7rem;
			line-height: 1;
			color: #FFF;
		}
	}
	& > .triangle{
		position: absolute;
		top: 50%;
		left: 0;
		z-index: 1;
		transform: translateY(-45%);
		width: 29rem;
		fill: ${ color.blue };
		opacity: 0.9;
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		& > .txt{
			left: 3rem;
			& > h2{
				& > svg{
					height: 2rem;
				}
			}
			& > p{
				margin-top: 1rem;
				font-size: 1.4rem;
			}
		}
		& > .triangle{
			width: 16rem;
		}
`}
`;

export default Hero;

