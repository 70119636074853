import React from 'react';

export const Num01 = props => (
  <svg viewBox="0 0 24 17.4" { ...props }>
    <g>
      <path d="M7.6,17.7A6.7,6.7,0,0,1,4.5,17a6.9,6.9,0,0,1-2.4-1.8A11.74,11.74,0,0,1,.6,12.5,18.7,18.7,0,0,1,.2,9,12.127,12.127,0,0,1,.7,5.6,10.621,10.621,0,0,1,2.2,2.8,6.9,6.9,0,0,1,4.6,1,7.448,7.448,0,0,1,7.7.3a6.04,6.04,0,0,1,3,.7,8.4,8.4,0,0,1,2.4,1.8,8.916,8.916,0,0,1,1.5,2.7,9.671,9.671,0,0,1,.5,3.4,12.127,12.127,0,0,1-.5,3.4,10.621,10.621,0,0,1-1.5,2.8,6.094,6.094,0,0,1-2.4,1.8,5.326,5.326,0,0,1-3.1.8Zm.1-3.3A2.41,2.41,0,0,0,9.2,14a6,6,0,0,0,1.2-1.1,4.356,4.356,0,0,0,.7-1.7,7.106,7.106,0,0,0,.3-2.1A6.648,6.648,0,0,0,11.1,7a5.306,5.306,0,0,0-.8-1.7A2.334,2.334,0,0,0,9.1,4.1a4.191,4.191,0,0,0-1.5-.4A2.7,2.7,0,0,0,6.1,4,5.731,5.731,0,0,0,5,5.1a3.8,3.8,0,0,0-.7,1.7A7.8,7.8,0,0,0,4,9a6.648,6.648,0,0,0,.3,2.1A6.69,6.69,0,0,0,5,12.8a3.179,3.179,0,0,0,1.2,1.1A2.456,2.456,0,0,0,7.7,14.4Z"/>
      <path d="M20.6,4.2l-2.9.7-.8-3L21.7.5h2.5V17.4H20.6Z"/>
    </g>
  </svg>
)

export const Num02 = props => (
  <svg viewBox="0 0 29.6 17.4" { ...props }>
    <g>
        <path d="M7.6,17.7A6.7,6.7,0,0,1,4.5,17a6.9,6.9,0,0,1-2.4-1.8A11.74,11.74,0,0,1,.6,12.5,18.7,18.7,0,0,1,.2,9,12.127,12.127,0,0,1,.7,5.6,10.621,10.621,0,0,1,2.2,2.8,6.9,6.9,0,0,1,4.6,1,7.448,7.448,0,0,1,7.7.3a6.04,6.04,0,0,1,3,.7,8.4,8.4,0,0,1,2.4,1.8,8.916,8.916,0,0,1,1.5,2.7,9.671,9.671,0,0,1,.5,3.4,12.127,12.127,0,0,1-.5,3.4,10.621,10.621,0,0,1-1.5,2.8,6.094,6.094,0,0,1-2.4,1.8,5.326,5.326,0,0,1-3.1.8Zm.1-3.3A2.41,2.41,0,0,0,9.2,14a6,6,0,0,0,1.2-1.1,4.356,4.356,0,0,0,.7-1.7,7.106,7.106,0,0,0,.3-2.1A6.648,6.648,0,0,0,11.1,7a5.306,5.306,0,0,0-.8-1.7A2.334,2.334,0,0,0,9.1,4.1a4.191,4.191,0,0,0-1.5-.4A2.7,2.7,0,0,0,6.1,4,5.731,5.731,0,0,0,5,5.1a3.8,3.8,0,0,0-.7,1.7A7.8,7.8,0,0,0,4,9a6.648,6.648,0,0,0,.3,2.1A6.69,6.69,0,0,0,5,12.8a3.179,3.179,0,0,0,1.2,1.1A2.456,2.456,0,0,0,7.7,14.4Z"/>
        <path d="M17.4,14.5,23,9.9a7.687,7.687,0,0,0,1.3-1.2,4.064,4.064,0,0,0,.9-1,4.053,4.053,0,0,0,.5-.9,2.958,2.958,0,0,0,.2-1,1.969,1.969,0,0,0-.6-1.6,2.271,2.271,0,0,0-1.6-.6,2.523,2.523,0,0,0-1.7.6,9.013,9.013,0,0,0-1.7,1.7L17.7,3.8A6.39,6.39,0,0,1,19,2.3a7.551,7.551,0,0,1,1.4-1.1A7.741,7.741,0,0,1,22,.5a6.478,6.478,0,0,1,2-.2,6.051,6.051,0,0,1,2.4.4,7.677,7.677,0,0,1,1.8,1,4.813,4.813,0,0,1,1.2,1.6,4.918,4.918,0,0,1,.4,2.1,5.822,5.822,0,0,1-.3,1.9,5.854,5.854,0,0,1-1,1.7,9.6,9.6,0,0,1-1.4,1.5c-.6.5-1.2,1-1.9,1.6l-2.7,2.1h7.3v3.2H17.4V14.5Z"/>
    </g>
  </svg>
)

export const Num03 = props => (
  <svg viewBox="0 0 29.4 17.4" { ...props }>
    <g>
        <path d="M7.6,17.7A6.7,6.7,0,0,1,4.5,17a6.9,6.9,0,0,1-2.4-1.8A11.74,11.74,0,0,1,.6,12.5,18.7,18.7,0,0,1,.2,9,12.127,12.127,0,0,1,.7,5.6,10.621,10.621,0,0,1,2.2,2.8,6.9,6.9,0,0,1,4.6,1,7.448,7.448,0,0,1,7.7.3a6.04,6.04,0,0,1,3,.7,8.4,8.4,0,0,1,2.4,1.8,8.916,8.916,0,0,1,1.5,2.7,9.671,9.671,0,0,1,.5,3.4,12.127,12.127,0,0,1-.5,3.4,10.621,10.621,0,0,1-1.5,2.8,6.094,6.094,0,0,1-2.4,1.8,5.326,5.326,0,0,1-3.1.8Zm.1-3.3A2.41,2.41,0,0,0,9.2,14a6,6,0,0,0,1.2-1.1,4.356,4.356,0,0,0,.7-1.7,7.106,7.106,0,0,0,.3-2.1A6.648,6.648,0,0,0,11.1,7a5.306,5.306,0,0,0-.8-1.7A2.334,2.334,0,0,0,9.1,4.1a4.191,4.191,0,0,0-1.5-.4A2.7,2.7,0,0,0,6.1,4,5.731,5.731,0,0,0,5,5.1a3.8,3.8,0,0,0-.7,1.7A7.8,7.8,0,0,0,4,9a6.648,6.648,0,0,0,.3,2.1A6.69,6.69,0,0,0,5,12.8a3.179,3.179,0,0,0,1.2,1.1A2.456,2.456,0,0,0,7.7,14.4Z"/>
        <path d="M23.7,17.7a9.346,9.346,0,0,1-3.9-.8,7.052,7.052,0,0,1-2.7-2.1l2.5-2.4a5.974,5.974,0,0,0,1.8,1.5,5.091,5.091,0,0,0,2.2.5,2.86,2.86,0,0,0,1.7-.5,1.955,1.955,0,0,0,.7-1.5,1.8,1.8,0,0,0-.9-1.6,4.286,4.286,0,0,0-2.4-.5H21.2L20.6,8l4-4H18V.6H29.4V3.4L25.1,7.5a5.488,5.488,0,0,1,1.7.5,4.826,4.826,0,0,1,1.4.9,3.587,3.587,0,0,1,1,1.4,5.917,5.917,0,0,1,.4,2,5.382,5.382,0,0,1-.4,2.2A5.229,5.229,0,0,1,28,16.2a6.15,6.15,0,0,1-1.9,1.2,8.26,8.26,0,0,1-2.4.3Z"/>
    </g>
  </svg>
)

