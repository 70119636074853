import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { color,breakpoint } from '@src/settings';

export const Row = styled.div`
	background-color: #FFF;
	display:flex;
	${ props => props.isReversed && `
		flex-direction: row-reverse;
	`}
	& > *{
		flex: 50% 0 0;
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		display: block;
	`}
`
export const Txt = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 6rem 5rem;
	position: relative;
	& > .btn{
		margin-top: 1.8rem;
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		padding: 3rem;
	`}
`

export const Title = styled.h3`
	font-size: 1.7rem;
	font-weight: bold;
	line-height: 1.3;
	margin-bottom: 1.8rem;
`

export const Cap = styled.p`
	font-size: 1.4rem;
	line-height: 1.8;
`

export const Num = styled.div`
	position: absolute;
	top: 3rem;
	${ props => props.isReversed ? 'left' : 'right'}: 3rem;
	width: 3.6rem;
	svg{
		height:2rem;
		width: auto;
	}
	${ media.lessThan(`${ breakpoint.tb }px`)`
		display: none;
	`}
`
