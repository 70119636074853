import React from 'react';
import styled from 'styled-components';
import * as SVG from '@components/strength/svgs';
import { Cont, Title, En, numCss } from './styles';

const Nav = props => {
	const Num = styled(SVG[`Num0${props.num}`])`
		${ numCss }
	`
	return (
		<Cont>
			<Num style={{ fill: "#F00" }}/>
			<Title>{ props.title }</Title>
			<En>{ props.en }</En>
		</Cont>
	)
}

export default Nav;
