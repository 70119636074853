import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import { createBreakpoint } from 'react-use';

import Layout from '@components/common/layout';
import ContactIntro from '@components/common/contact-intro';
import Hero from '@components/common/hero';
import PageIntro from '@components/common/page-intro';
import ContentsIntro from '@components/common/contents-intro';
import Btn from '@components/common/btn';

import Hero__mds from '@img/svg/strength/hero__mds.svg';
import Mds01 from '@img/svg/strength/mds01.svg';
import Mds02 from '@img/svg/strength/mds02.svg';
import Mds03 from '@img/svg/strength/mds03.svg';

import Column from '@components/strength/column';
import Nav from '@components/strength/nav';
import { NavCont, Container } from '@components/strength/styles';

import { mixin, color, breakpoint } from '@src/settings';
const useBreakpoint = createBreakpoint(breakpoint);

const StrengthPage = ({ data }) => {

	const bp = useBreakpoint();

	return (
	<Layout baseBgColor={ color.paleGray }>
		<Hero
			img={ data["heroImg"] }
			title={<Hero__mds />}
			subTitle="私たちの強み"
		/>

		<PageIntro
			title={<>マレーシアにおける車体部品製造のパイオニア</>}
			subtitle={<>世界に視野を広げてトヨタ生産方式を導入し、2000年からは一貫生産体制を実施。お客様のニーズに合ったクオリティと納期、そしてコスト競争力を叶えるパートナー企業として成長してまいりました。より良い品質を追求する熱意ある社員とともに、車体部品製造分野のパイオニアとして、マレーシアの自動車業界に貢献してまいります。</>}
		/>

		<Container>	
			<NavCont>
				<Nav
					num={ 1 }
					title="モノづくりの基本は人づくりから"
					en={ <Mds01 /> }
				/>
				<Nav
					num={ 2 }
					title="マレーシア唯一の一貫生産体制"
					en={ <Mds02 /> }
				/>
				<Nav
					num={ 3 }
					title="日本企業の技術支援を受けて成長"
					en={ <Mds03 /> }
				/>
			</NavCont>

			<Column
				className="column"
				num={ 1 }
				img={ bp == "pc" ? data["Strength_1_200311"] : data["Strength_1_sp"] }
				imgPos="65% 50%"
				title="モノづくりの基本は人づくりから"
				cap="モノづくりに携わる企業に最も重要なのは、人を育てる事です。当社では、オペレーションを担う現場メンバーと、マネージメントする人間とが一体となり、様々な課題を迅速に解決・改善します。コミュニーケーション力のある人を育てることで明るい現場・明るい工場になり、企業がひとつのチームになります。メンバー一人ひとりの声が、全員に届く環境をめざしています。"
			/>

			<Column
				className="column"
				num={ 2 }
				img={ bp == "pc" ? data["Strength_2_200311"] : data["Strength_2_sp"] }
				imgPos="90% 50%"
				title="マレーシア唯一の一貫生産体制"
				cap="当社では、ご要望に合わせた自動車のボディパーツのプレス用金型の設計・製造、そのパーツのプレス、溶接から組み立てに至るまで、ワンストップで請け負っています。自社工場で一貫生産体制をしている工場は、マレーシアで他にありません。同じ工場内ですべての工程を行えることがリードタイムの短縮、優れた品質へとつながっています。"
				to="/products"
				btnTxt="製品紹介"
				isReversed
			/>

			<Column
				className="column"
				num={ 3 }
				img={ bp == "pc" ? data["Strength_3_200311"] : data["Strength_3_sp"] }
				title="日本企業の技術支援を受けて成長"
				cap="2代目社長は海を渡り、日本のトヨタ車体にて3年、知識と経験を積みました。そこで学んだトヨタ生産方式（TPS）が、当社の事業運営のベースとなっています。現在も日本人アドバイザー・日本パートナー企業と提携し、最新技術はもちろん、長年の経験からくる熟練の技を体得しながら、当社独自の技術力を構築しています。"
			/>
		</Container>

		<ContentsIntro
			firstItem="services"
			secondItem="facility"
		/>
	</Layout>
	)
}


export const query = graphql`
	query strengthImgs{

		heroImg: file( relativePath: { eq: "strength/hero-img.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Strength_1_200311: file( relativePath: { eq: "strength/strength-1_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Strength_2_200311: file( relativePath: { eq: "strength/strength-2_200624.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Strength_3_200311: file( relativePath: { eq: "strength/strength-3_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Strength_1_sp: file( relativePath: { eq: "strength/strength-1--sp.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Strength_2_sp: file( relativePath: { eq: "strength/strength-2--sp_200624.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Strength_3_sp: file( relativePath: { eq: "strength/strength-3--sp.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
	},

		Subblock_service: file( relativePath: { eq: "common/subblock_service.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},


		Subblock_facility: file( relativePath: { eq: "common/subblock__facility.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
	}
`

export default StrengthPage
