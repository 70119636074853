import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { mixin, breakpoint } from '@src/settings';

export const NavCont = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 8rem;
	${ media.lessThan(`${ breakpoint.tb }px`)`
	 display: none;
	`}
`

export const Container = styled.div`
	${ mixin.baseWidthSet }
	margin-top: 8rem;
	margin-bottom: 8rem;
	& > .column + .column{
		margin-top: 5rem;
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		margin-top: 6rem;
		margin-bottom: 6rem;
	`}
`
