import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import Btn from '@components/common/btn';
import { Row, Txt, Title, Cap, Num } from './styles';
import * as No from '@components/strength/svgs';

type Props = {
	className: string,
	isReversed? : boolean,
	img : opject,
	imgPos? : string,
	title : string,
	cap : string,
	to? : string,
	btnTxt? : string,
}
const Column = props => {
	const CurNo = No[`Num0${ props.num }`];
	return (
		<Row className={ props.className } isReversed={ props.isReversed }>
			<Img
				fluid={ props.img.childImageSharp.fluid }
				objectPosition={ props.imgPos }
			/>
			<Txt>
				<Num isReversed={ props.isReversed }><CurNo style={{ fill: "#E00" }}/></Num>
				<Title>{ props.title }</Title>
				<Cap>{ props.cap }</Cap>
				{ props.to && (
					<Btn to={ props.to } className="btn">{ props.btnTxt }</Btn>
				)}
			</Txt>
		</Row>
	)
}

export default Column;
